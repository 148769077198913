import React, { FC } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Path } from 'routes';
import { useApp } from 'context/AppContext';
import { ReactComponent as IconLogo } from 'logo.svg';
import { ReactComponent as IconMenu } from 'assets/icons/header-menu.svg';
import { ReactComponent as IconBack } from 'assets/icons/header-back.svg';
import { Component, Logo, Title, Menu, Nav} from './Header.styled';
import {useTranslation} from "react-i18next";

export const Header:FC = () => {
    const { title, menu } = useApp();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    if (pathname === Path.private.registration) return null;
    // That's temporary decision for keeping default header place, cause if it isn't markup is going to be broken
    if (pathname.includes('chat')) return <Component/>

    const goBack = () => {
        navigate(-1);
    }

    return (
        <Component>
            { pathname === Path.public.main
                ? (
                    <Logo>
                        <Link to={Path.public.main}>
                            <IconLogo/>
                            <h1>{ title }</h1>
                        </Link>
                    </Logo>
                )
                : (
                    <Nav onClick={goBack}>
                        <IconBack/>
                        <h1>{ title }</h1>
                    </Nav>
                )
            }
            {
                pathname !== Path.private.registration
                && (
                    <Menu isActiveMenu={menu.active}>
                        <span>18+</span>
                        <button name="menu" onClick={menu.toggle}>
                            <IconMenu/>
                        </button>
                    </Menu>
                )
            }
        </Component>
    );
};
